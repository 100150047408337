<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Centro de Costo" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { AccountingCostCenter } from '../../../models/contabilidad/AccountingCostCenter';

export default {
  data() {
    return {
      entity: new AccountingCostCenter(),
      inputs: [
        new CatalogInput("Nombre", "name", "text"),
        new CatalogInput("Clave", "key_name", "text"),
        new CatalogInput("Tipo", "type", "dropdown", { options: [{ value: 1, text: 'ADMINISTRATIVO'},
        { value: 2, text: 'SERVICIOS'},
        { value: 3, text: 'VENTAS'},
        { value: 4, text: 'PRODUCCION'}], options_value: 'value', options_label: 'text'}),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name")
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new AccountingCostCenter(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new AccountingCostCenter(this.session); 
  }
}
</script>

<style>

</style>